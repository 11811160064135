import { useStaticQuery, graphql } from 'gatsby'

export const getVideoHeroData = () => {
  const { allStrapiCommonHero } = useStaticQuery(videoHeroDataQuery)
  let videoHeroData = allStrapiCommonHero.nodes[0].Heros.filter(
    value => Object.keys(value).length !== 0
  )
  return videoHeroData[0]
}

const videoHeroDataQuery = graphql`
  query {
    allStrapiCommonHero {
      nodes {
        Heros {
          ... on STRAPI__COMPONENT_SHARED_VIDEO_HERO {
            id
            button {
              id
              name
              url
            }
            description {
              data {
                description
              }
            }
            title
          }
        }
      }
    }
  }
`
