import { useStaticQuery, graphql } from 'gatsby'

export const getHeroData = () => {
  const { allStrapiCommonHero } = useStaticQuery(heroDataQuery)
  let heroData = allStrapiCommonHero.nodes[0].Heros.filter(
    value => Object.keys(value).length !== 0
  )
  return heroData[0]
}

const heroDataQuery = graphql`
query {
  allStrapiCommonHero {
    nodes {
      Heros {
        ... on STRAPI__COMPONENT_SHARED_POPE_FRANCIS_HERO {
          id
          button {
            id
            name
            url
          }
          description {
            data {
              description
            }
          }
          overlay {
            data {
              overlay
            }
          }
          title
        }
      }
    }
  }
}
`