import React from 'react'
import axios from './base'

class Apis extends React.Component {
  constructor() {
    super('')
  }

  getToken() {
    return JSON.parse(localStorage.getItem('digital_token'))
  }

  clearToken() {
    localStorage.removeItem('digital_token')
  }

  authorizeUser() {
    return axios.get('oauth/token', {
      auth: {
        username: process.env.GATSBY_USER_NAME,
        password: process.env.GATSBY_PASSWORD
      }
    })
  }

  async getCollectionList() {
    let result = this.getToken()
    if (
      !result ||
      new Date(result.expiresIn).getTime() < new Date().getTime()
    ) {
      const { data } = await this.authorizeUser()
      result = data
      localStorage.setItem('digital_token', JSON.stringify(data))
    }
    return axios.post(
      'digital_collection/list',
      {
     name:'Pope Francis and Francis of Assisi',
        page:1,
        perPage:1
      },
      {
        headers: {
          oauthAccessToken: result.accessToken
        }
      }
    )
  }

  async getSubCollection(id) {
    let result = this.getToken()
    if (
      !result ||
      new Date(result.expiresIn).getTime() < new Date().getTime()
    ) {
      const { data } = await this.authorizeUser()
      result = data
      localStorage.setItem('digital_token', JSON.stringify(data))
    }    return axios.get(`digital_collection/sub_collection/${id}`, {
      headers: {
        oauthAccessToken: result.accessToken
      }
    })
  }

  async getCategoryData(category) {
    let result = this.getToken()
    if (
      !result ||
      new Date(result.expiresIn).getTime() < new Date().getTime()
    ) {
      const { data } = await this.authorizeUser()
      result = data
      localStorage.setItem('digital_token', JSON.stringify(data))
    }
    const filterQuery = {
      page: 1,
      perPage: 20,
      fieldToSort: 'label',
      order: 'asc',
      searchOption: 'metadata',
      searchType: 'all_words',
      filter: {
        collection: category
      }
    }

    return axios.post('digital_document/search/index', filterQuery, {
      headers: {
        oauthAccessToken: result.accessToken
      }
    })
  }
}
const instance = new Apis()
export default instance
