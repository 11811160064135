import { useStaticQuery, graphql } from 'gatsby'

// :::: GET Article from Article title ::::
export const getStrapiSeoDataFromTitle = title => {
  const { partner, video, blog, event } = useStaticQuery(allStrapiBlogs)
  const list = [...partner.nodes, ...video.nodes, ...blog.nodes, ...event.nodes]
  let item = list.filter(node => node.title === title)
  return item[0]
}

const allStrapiBlogs = graphql`
  query {
    partner: allStrapiPartner {
      nodes {
        title
        seo {
          id
          metaDescription
          keywords
          metaTitle
          metaImage {
            url
          }
        }
      }
    }

    video: allStrapiVideo {
      nodes {
        title
        seo {
          id
          metaDescription
          keywords
          metaTitle
          metaImage {
            url
          }
        }
      }
    }

    event: allStrapiEvent {
      nodes {
        title
        seo {
          id
          metaDescription
          keywords
          metaTitle
          metaImage {
            url
          }
        }
      }
    }

    blog: allStrapiBlog {
      nodes {
        title
        seo {
          id
          metaDescription
          keywords
          metaTitle
          metaImage {
            url
          }
        }
      }
    }
  }
`
