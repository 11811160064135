import React from 'react'
import { Card, Box } from 'theme-ui'
import Section from '@components/Section'

const styles = {
  wrapper: {
    display: `flex`,
    alignItems: `flex-end`,
    position: `relative`,
    mt: [0, null, 5]
  },
  customWrapper: {
    display: `flex`,
    alignItems: `flex-end`,
    position: `relative`,
    mt: [0, null, 5],
    backgroundColor: 'transparent',
    boxShadow: 'none',
    marginTop: `0 !important`,
    padding: `4rem 0 !important`
  },
  leftColumn: {
    flexBasis: `2/3`,
    flex: 1,
    position: `relative`,
    zIndex: 3
  },
  customLeftColumn: {
    flexBasis: `60%`,
    flex: 1,
    position: `relative`,
    zIndex: 3
  },
  rightColumn: {
    display: [`none`, `none`, null, `block`],
    flexBasis: `1/3`
  },
  customRightColumn: {
    display: [`none`, `none`, null, `block`],
    flexBasis: `40%`
  }
}

const LeftColumn = ({ children, ...props }) => (
  <Box sx={props.customHeroLeftFlag ? styles.customLeftColumn : styles.leftColumn}>{children}</Box>
)

const RightColumn = ({ children, ...props }) => (
  <Box sx={props.customHeroRightFlag ? styles.customRightColumn : styles.rightColumn}>{children}</Box>
)

const Wrapper = ({ children, ...props }) => {
  return <Section>
    <Card variant='paper-lg' sx={props.customHeroBoxFlag ? styles.customWrapper : styles.wrapper}>
      {children}
    </Card>
  </Section>
}

export default {
  Wrapper,
  LeftColumn,
  RightColumn
}
