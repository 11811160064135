import React, { useEffect, useState } from 'react'
import { Layout, Stack, Hero, Main, Sidebar, PreFooter } from '@layout'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import { Card, Text, Button, Box, Heading } from 'theme-ui'
import { Link } from 'gatsby'
import Apis from '../apis/francis'
import Section from '@components/Section'
import { getStaticPageSeoDetails } from '@utils-blog'
import HeroComponent from '../../../../themes/gatsby-theme-flexiblog-personal/src/components/Hero/Hero'
import MemphisPattern from '@components/MemphisPattern/MemphisPattern'

const PopeFrancis = props => {
  const [list, setList] = useState([])
  let tempArray = []
  useEffect(() => {
    getList()
  }, [])

  const styles = {
    guide: {
      mb: 2,
      ml: 2
    },
    image: {
      width: '100%',
      height: '200px',
      objectFit: 'cover'
    },
    label: {
      marginBottom: `1rem`,
      fontSize: `1.25rem`
    },
    description: {
      display: '-webkit-box',
      '-webkit-line-clamp': '6',
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden'
    },
    gridCol: {
      display: 'grid',
      gridTemplateColumns: '33% 33% 33%',
      gap: '20px',
      '@media screen and (max-width: 600px)': {
        gridTemplateColumns: 'auto'
      },
      '@media screen and (min-width: 601px) and (max-width: 1024px)': {
        gridTemplateColumns: 'auto auto'
      },
      '>div': {
        bg: `omegaLighter`,
        borderRadius: `1rem`,
        overflow: `hidden`,
        '>div': {
          px: 20,
          pb: 20,
          pt: 10
        }
      }
    }
  }

  const getList = async () => {
    tempArray = []
    const { data } = await Apis.getCollectionList()
    const filteredCollection = data.data.filter(
      item => item.name === 'Pope Francis and Francis of Assisi'
    )
    if (filteredCollection.length > 0) {
      const { data } = await Apis.getSubCollection(filteredCollection[0].id)
      for (const item of data.data) {
        const response = await Apis.getCategoryData(item.name)
        response.data.data.forEach(element => {
          let splitLabel = ''
            splitLabel = element.label.replace(/[^\w\s-]/gi, '').toLowerCase()
            splitLabel = splitLabel.replace(/ /g, '_')
          element.identifier = encodeURIComponent(element.identifier).replace(
            /[!'()*]/g,
            function (c) {
              return '%' + c.charCodeAt(0).toString(16)
            }
          )
          element.key = item.name
          element.url = `${process.env.GATSBY_REDIRECT_URL}${
            element.identifier
          }/${splitLabel}/${element.date.toLowerCase()}`
          tempArray.push(element)
        })
      }
      const list = groupBy([...tempArray], c => c.key)
      setList(list)
    }
    function groupBy(xs, f) {
      return xs.reduce(
        (r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r),
        {}
      )
    }
  }

  let seoData = getStaticPageSeoDetails('shared.pope-francis-page')

  const francisSeoData = {
    ...seoData,
    Francis: {
      ...seoData?.Francis,
      keywords: seoData?.Francis?.keywords?.split('\n')
    }
  }

  return (
    <Layout {...props}>
      <Seo
        title={francisSeoData?.Francis?.metaTitle}
        description={francisSeoData?.Francis?.metaDescription}
        keywords={francisSeoData?.Francis?.keywords}
        thumbnail={francisSeoData?.Francis?.metaImage}
      />
      <Hero full>
        <Hero sx={{ padding: `50px 0` }}>
          <HeroComponent
            customHeroFlag={true}
            heroType={'popeFrancis'}
            {...props}
            callFrom='Pope Francis and Francis'
          />
        </Hero>
        <MemphisPattern customHeroBgFlag={true} />
      </Hero>
      <Divider />
      <Stack>
        <Main>
          {Object.keys(list).map((key, index) => {
            return (
              <Section title={key}>
                <Box sx={styles.gridCol}>
                  {list[key].map(item => {
                    return (
                      <Box variant='paper'>
                        <img
                          src={item.thumbnail.imageUrl.replace(
                            'full/100',
                            'full/300'
                          )}
                          alt={item.label}
                          style={styles.image}
                        />
                        <div>
                          <Heading variant={['title']}>
                            <Text variant={['card-title']} style={styles.label}>
                              {item.label}
                            </Text>
                          </Heading>
                          <Text variant='p' style={styles.description}>
                            {item.description}
                          </Text>
                          <Button
                            variant='primary'
                            as={Link}
                            target='_blank'
                            to={item.url}
                          >
                            Read More
                          </Button>
                        </div>
                      </Box>
                    )
                  })}
                </Box>
                <Divider />
              </Section>
            )
          })}
          <Divider />
        </Main>
      </Stack>
    </Layout>
  )
}
export default PopeFrancis
