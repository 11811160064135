import { useStaticQuery, graphql } from 'gatsby'

// :::: GET Article from Article title ::::
export const getStrapiSeoData = type => {
  const { allStrapiSeo } = useStaticQuery(allStrapistaticSeo)

  const list = allStrapiSeo?.nodes[0]?.Seo
  let item = list.find(node => node.strapi_component === type)
  return item
}

const allStrapistaticSeo = graphql`
  query {
    allStrapiSeo {
      nodes {
        Seo {
          ... on STRAPI__COMPONENT_SHARED_BLOGS {
            id
            Blogs: Seo {
              keywords
              metaDescription
              metaTitle
              metaImage {
                url
              }
            }
            strapi_component
          }
          ... on STRAPI__COMPONENT_SHARED_CUSTODIANS_SERIES_PAGE {
            id
            Custodians: Seo {
              keywords
              metaTitle
              metaDescription
              metaImage {
                url
              }
            }
            strapi_component
          }
          ... on STRAPI__COMPONENT_SHARED_EVENTS {
            id
            Events: Seo {
              metaTitle
              keywords
              metaDescription
              metaImage {
                url
              }
            }
            strapi_component
          }
          ... on STRAPI__COMPONENT_SHARED_HERITAGE_SERIES {
            id
            Heritage: Seo {
              metaTitle
              keywords
              metaDescription
              metaImage {
                url
              }
            }
            strapi_component
          }
          ... on STRAPI__COMPONENT_SHARED_HOME_PAGE {
            id
            Home: Seo {
              metaTitle
              metaDescription
              keywords
              metaImage {
                url
              }
            }
            strapi_component
          }
          ... on STRAPI__COMPONENT_SHARED_PARTNERS {
            id
            Partners: Seo {
              id
              metaDescription
              keywords
              metaTitle
              metaImage {
                url
              }
            }
            strapi_component
          }
          ... on STRAPI__COMPONENT_SHARED_BOARD_MEMBERS {
            id
            BoardMembers: Seo {
              id
              metaDescription
              keywords
              metaTitle
              metaImage {
                url
              }
            }
            strapi_component
          }
          ... on STRAPI__COMPONENT_SHARED_POPE_FRANCIS_PAGE {
            id
            Francis: Seo {
              keywords
              metaDescription
              metaTitle
              metaImage {
                url
              }
            }
            strapi_component
          }
          ... on STRAPI__COMPONENT_SHARED_SYMPOSIUM_SERIES {
            id
            Symposium: Seo {
              metaDescription
              metaTitle
              keywords
              metaImage {
                url
              }
            }
            strapi_component
          }
          ... on STRAPI__COMPONENT_SHARED_VIDEOS {
            Videos: Seo {
              keywords
              metaDescription
              metaTitle
              metaImage {
                url
              }
            }
            strapi_component
          }
          ... on STRAPI__COMPONENT_SHARED_OTHER_RESOURCES {
            id
            Other_Resources: Seo {
              metaTitle
              keywords
              metaDescription
              metaImage {
                url
              }
            }
            strapi_component
          }
          ... on STRAPI__COMPONENT_SHARED_ABOUT {
            id
            About: Seo {
              metaDescription
              keywords
              metaTitle
              metaImage {
                url
              }
            }
            strapi_component
          }
        }
      }
    }
  }
`
