import { useStaticQuery, graphql } from 'gatsby'

export const getCustodiansTraditionHeroData = () => {
  const { allStrapiCommonHero } = useStaticQuery(custodiansTraditionHeroDataQuery)
  let custodiansHeroData = allStrapiCommonHero.nodes[0].Heros.filter(
    value => Object.keys(value).length !== 0
  )
  return custodiansHeroData[0]
}

const custodiansTraditionHeroDataQuery = graphql`
  query {
    allStrapiCommonHero {
      nodes {
        Heros {
          ... on STRAPI__COMPONENT_SHARED_CUSTODIANS_OF_THE_TRADITION_HERO {
            id
            button {
              id
              name
              url
            }
            description {
              data {
                description
              }
            }
            title
          }
        }
      }
    }
  }
`
