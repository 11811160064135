import { useStaticQuery, graphql } from 'gatsby'

export const getHeritageSeriesHeroData = () => {
  const { allStrapiCommonHero } = useStaticQuery(heritageSeriesHeroDataQuery)
  let heritageHeroData = allStrapiCommonHero.nodes[0].Heros.filter(
    value => Object.keys(value).length !== 0
  )
  return heritageHeroData[0]
}

const heritageSeriesHeroDataQuery = graphql`
  query {
    allStrapiCommonHero {
      nodes {
        Heros {
          ... on STRAPI__COMPONENT_SHARED_HERITAGE_SERIES_HERO {
            id
            button {
              id
              name
              url
            }
            description {
              data {
                description
              }
            }
            title
          }
        }
      }
    }
  }
`
