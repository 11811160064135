import React from 'react'
import { Link } from 'gatsby'
import { Box, Heading, Button } from 'theme-ui'
import Section from '@components/Section'
import { getPopeFrancisHeroData } from '../../helpers/nr-PopeFrancis.helper'
import { getHeritageSeriesHeroData } from '../../helpers/nr-heritage-series-hero.helper'
import { getSymposiumSeriesHeroData } from '../../helpers/nr-symposium-series-hero'
import { getPartnerHeroData } from '../../helpers/nr-partner-hero.helper'
import { getCustodiansTraditionHeroData } from '../../helpers/nr-custodians-tradition-hero.helper'
import { getOtherResourceHeroData } from '../../helpers/nr-other-resource-hero'
import { getVideoHeroData } from '../../helpers/nr-video-hero.helper'
import { getAboutHeroData } from '../../helpers/nr-about-hero'
import { getEventHeroData } from '../../helpers/nr-event-hero.helper'
import {getBoardMemberHeroData  } from '../../helpers/nr-board-member-hero-helper'

/**
 * Shadow me to add your own content
 */

const styles = {
  heading: {
    mb: 4,
    span: {
      color: `omegaDark`
    }
  },
  running: {
    color: `omegaDark`,
    fontWeight: `body`,
    width: `5/6`
  },
  stringHtml: {
    p: {
      m: 0
    }
  },
  buttons: {
    a: {
      borderRadius: `12px`
    }
  }
}

export default ({ callFrom = 'Home', ...props }) => {
  const popeHeroData = getPopeFrancisHeroData()
  const heritageHeroData = getHeritageSeriesHeroData()
  const symposiumHeroData = getSymposiumSeriesHeroData()
  const custodiansHeroData = getCustodiansTraditionHeroData()
  const otherResourceHeroData = getOtherResourceHeroData()
  const partnerHeroData = getPartnerHeroData()
  const boardMemberHeroData = getBoardMemberHeroData()
  const videoHeroData = getVideoHeroData()
  const aboutHeroData = getAboutHeroData()
  const eventHeroData = getEventHeroData()
 
  let heroData = popeHeroData
  if (props.heroType === 'popeFrancis') {
    heroData = popeHeroData
  }
  if (props.heroType === 'heritageSeries') {
    heroData = heritageHeroData
  }
  if (props.heroType === 'symposiumSeries') {
    heroData = symposiumHeroData
  }
  if (props.heroType === 'CustodiansOfTheTradition') {
    heroData = custodiansHeroData
  }
  if (props.heroType === 'otherResources') {
    heroData = otherResourceHeroData
  }
  if (props.heroType === 'video') {
    heroData = videoHeroData
  }
  if (props.heroType === 'about') {
    heroData = aboutHeroData
  }
  if (props.heroType === 'event') {
    heroData = eventHeroData
  }
  if(props.heroType === "partnerHero"){
    heroData = partnerHeroData
  }
  if(props.heroType === "boardMembers"){
    heroData = boardMemberHeroData
  }

  return (
    <>
      <Section>
        <Heading variant='h1' sx={styles.heading}>
          {heroData.title}
        </Heading>
        <Heading variant='h3' sx={styles.running}>
          <Box
            sx={styles.stringHtml}
            dangerouslySetInnerHTML={{
              __html: heroData.description.data.description
            }}
          />
        </Heading>
      </Section>
      <Box variant='buttons.group' sx={styles.buttons}>
        {heroData.button.map((btn, index) => {
          if (btn.name !== '') {
            if (index % 2 !== 0) {
              return (
                <Button as={Link} variant='white' to={btn.url}>
                  {btn.name}
                </Button>
              )
            } else {
              return (
                <Button as={Link} to={btn.url}>
                  {btn.name}
                </Button>
              )
            }
          }
        })}
      </Box>
    </>
  )
}
