import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Box } from 'theme-ui'

const styles = {
  image: image => ({
    display: [`none`, null, `block`],
    height: `120%`,
    width: `1/2`,
    minWidth: 380,
    maxHeight: 514,
    borderRadius: `default`,
    backgroundImage: `url(${image})`,
    backgroundRepeat: `no-repeat`,
    backgroundSize: `cover`,
    // filter: `grayscale(1)`,
    overflowX: `hidden`,
    position: `absolute`,
    bottom: 0,
    right: 0,
    zIndex: 2
  }),
  customImage: image => ({
    display: [`none`, null, `block`],
    height: `275`,
    width: `1/3`,
    minWidth: 350,
    maxHeight: 275,
    minHeight: 275,
    borderRadius: `default`,
    backgroundImage: `url(${image})`,
    backgroundRepeat: `no-repeat`,
    backgroundSize: `cover`,
    backgroundColor: `#d1d4d9`,
    // filter: `grayscale(1)`,
    overflowX: `hidden`,
    position: `absolute`,
    transform: 'translateY(-50%)',
    borderRadius: `0 40px 40px 40px`,
    top: `50%`,
    right: 0,
    zIndex: 2
  }),
  border: {
    '&::before, &::after': {
      content: `""`,
      position: `absolute`
    },
    '::before': {
      bg: `#E4E7EB`,
      top: `calc(50% - 147px)`,
      right: `calc(33% - 163px)`,
      width: `175px`,
      height: `133px`
    },
    '::after': {
      bg: `#DBE0E7`,
      width: `175px`,
      height: `133px`,
      right: `-8px`,
      top: `calc(50% - -11px)`,
      borderRadius: `0px 3px 40px 0px`
    }
  }
}

export default ({ callFrom = 'Home', ...props }) => {
  const {
    francis,
    heritageSeries,
    symposiumSeries,
    CustodiansOfTheTradition,
    otherResources,
    video,
    boardMembers,
    about, 
    event,
    partner
  } = useStaticQuery(heroQuery)

  let publicURL
  if (props.heroType === 'popeFrancis') {
    let francisHeroData = francis.nodes[0].Heros.filter(
      value => Object.keys(value).length !== 0
    )
    publicURL = francisHeroData[0].featuredImage.url

    if (!publicURL) return ''
  }

  if (props.heroType === 'heritageSeries') {
    let francisHeroData = heritageSeries.nodes[0].Heros.filter(
      value => Object.keys(value).length !== 0
    )
    publicURL = francisHeroData[0].featuredImage.url

    if (!publicURL) return ''
  }

  if (props.heroType === 'symposiumSeries') {
    let francisHeroData = symposiumSeries.nodes[0].Heros.filter(
      value => Object.keys(value).length !== 0
    )
    publicURL = francisHeroData[0].featuredImage.url

    if (!publicURL) return ''
  }

  if (props.heroType === 'CustodiansOfTheTradition') {
    let francisHeroData = CustodiansOfTheTradition.nodes[0].Heros.filter(
      value => Object.keys(value).length !== 0
    )
    publicURL = francisHeroData[0].featuredImage.url

    if (!publicURL) return ''
  }

  if (props.heroType === 'otherResources') {
    let francisHeroData = otherResources.nodes[0].Heros.filter(
      value => Object.keys(value).length !== 0
    )
    publicURL = francisHeroData[0].featuredImage.url

    if (!publicURL) return ''
  }

  if (props.heroType === 'video') {
    let francisHeroData = video.nodes[0].Heros.filter(
      value => Object.keys(value).length !== 0
    )
    publicURL = francisHeroData[0].featuredImage.url

    if (!publicURL) return ''
  }

  if (props.heroType === 'about') {
    let francisHeroData = about.nodes[0].Heros.filter(
      value => Object.keys(value).length !== 0
    )
    publicURL = francisHeroData[0].featuredImage.url

    if (!publicURL) return ''
  }

  if (props.heroType === 'event') {
    let francisHeroData = event.nodes[0].Heros.filter(
      value => Object.keys(value).length !== 0
    )
    publicURL = francisHeroData[0].featuredImage.url

    if (!publicURL) return ''
  }

  if (props.heroType === 'partnerHero') {
    let francisHeroData = partner.nodes[0].Heros.filter(
      value => Object.keys(value).length !== 0
    )

    publicURL = francisHeroData[0].featuredImage.url

    if (!publicURL) return ''
  }

  if (props.heroType === 'boardMembers') {
    let francisHeroData = boardMembers.nodes[0].Heros.filter(
      value => Object.keys(value).length !== 0
    )
    publicURL = francisHeroData[0].featuredImage.url

    if (!publicURL) return ''
  }

  return (
    <Box sx={styles.border}>
      <Box
        sx={
          props.customHeroPhotoFlag
            ? styles.customImage(publicURL || {})
            : styles.image(publicURL || {})
        }
      ></Box>
    </Box>
  )
}

const heroQuery = graphql`
  query {
    francis: allStrapiCommonHero {
      nodes {
        Heros {
          ... on STRAPI__COMPONENT_SHARED_POPE_FRANCIS_HERO {
            id
            featuredImage {
              url
            }
          }
        }
      }
    }
    heritageSeries: allStrapiCommonHero {
      nodes {
        Heros {
          ... on STRAPI__COMPONENT_SHARED_HERITAGE_SERIES_HERO {
            id
            featuredImage {
              url
            }
          }
        }
      }
    }
    symposiumSeries: allStrapiCommonHero {
      nodes {
        Heros {
          ... on STRAPI__COMPONENT_SHARED_SYMPOSIUM_SERIES_HERO {
            id
            featuredImage {
              url
            }
          }
        }
      }
    }
    CustodiansOfTheTradition: allStrapiCommonHero {
      nodes {
        Heros {
          ... on STRAPI__COMPONENT_SHARED_CUSTODIANS_OF_THE_TRADITION_HERO {
            id
            featuredImage {
              url
            }
          }
        }
      }
    }
    otherResources: allStrapiCommonHero {
      nodes {
        Heros {
          ... on STRAPI__COMPONENT_SHARED_OTHER_RESOURCES_HERO {
            id
            featuredImage {
              url
            }
          }
        }
      }
    }
    video: allStrapiCommonHero {
      nodes {
        Heros {
          ... on STRAPI__COMPONENT_SHARED_VIDEO_HERO {
            id
            featuredImage {
              url
            }
          }
        }
      }
    }
    about: allStrapiCommonHero {
      nodes {
        Heros {
          ... on STRAPI__COMPONENT_SHARED_ABOUT_HERO {
            id
            featuredImage {
              url
            }
          }
        }
      }
    },
    event: allStrapiCommonHero {
      nodes {
        Heros {
          ... on STRAPI__COMPONENT_SHARED_EVENTS_HERO {
            id
            featuredImage {
              url
            }
          }
        }
      }
    },
    partner: allStrapiCommonHero {
      nodes {
        Heros {
          ... on STRAPI__COMPONENT_SHARED_PARTNER_HERO {
            id
            featuredImage {
              url
            }
          }
        }
      }
    }
    boardMembers: allStrapiCommonHero {
      nodes {
        Heros {
          ... on STRAPI__COMPONENT_SHARED_BOARD_MEMBERS_HERO {
            id
            featuredImage {
              url
            }
          }
        }
      }
    }
  }
`
